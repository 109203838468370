@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

body {
  margin: 0;
  font-family: 'Oswald', sans-serif;
}

/* .hero {
  background: url(./images/cool-background.svg) top center no-repeat;
  background-position: 15% 50%;
  background-size: cover;
} */

/* .footer {
  background: url(./images/footerbg.png) bottom center no-repeat;
  background-size: cover;
} */

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(./images/polufood.jpg) fixed center no-repeat;
  background-size: cover;
}


